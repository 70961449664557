@import "src/scss/module";

.banner {
  background-image: url("/assets/banners/bg.png");
  background-size: cover;
  border: 1px solid #D2D1D1;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 420px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom right;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__backlink {
    display: flex;
    text-decoration: none;
    align-items: center;
    color: $primary;
    transition: .3s;

    &:hover {
      color: $secondary;
    }
  }

  &__header {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 1.5s, opacity 2.5s;
  }

  &[class*=visible] &__header {
    opacity: 1;
    transform: translateY(0%);
  }

  &__content {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 1.5s, opacity 2.5s;
  }

  &[class*=visible] &__content {
    opacity: 1;
    transform: translateY(0%);
  }
}