@import "src/scss/module";

.services {

  @include media-breakpoint-up(md) {
    h2 {
      writing-mode: vertical-lr;
      transform: rotate(180deg) translateX(100%);
      position: absolute;
      bottom: -8px;
      left: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      &:before {
        content: '';
        display: block;
        background-color: $primary;
        height: 110px;
        width: 5px;
        margin-bottom: 20px;
      }
    }
  }

  [class*=col-] {
    position: relative;
    min-height: 480px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 2;
    }

    &:nth-of-type(3) {
      order: 3;

      @include media-breakpoint-up(md) {
        order: 4;
      }
    }

    &:nth-of-type(4) {
      order: 4;

      @include media-breakpoint-up(md) {
        order: 3;
      }

    }
  }
}