@import "src/scss/module";

.content {

  &__tag {
    position: absolute;
    overflow: visible;
    width: 0;
    left: 0;
    transform: translateY(-3rem);
    color: rgba($primary, 0.1);
    font-weight: $font-weight-bold;
    font-size: clamp(100px, 20vw, 250px);
    line-height: 0.8;
    pointer-events: none;
    overflow-wrap: normal;
    z-index: 0;
  }
}